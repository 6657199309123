
<div class="sidebar-content">

    <div class="icon-container">
        <div class="icon">
            <a href="https://www.facebook.com/wuenschmirwas.at/" target="_new">
            <img src="assets/img/facebook_white.png" alt="Follow us on Facebook wünsch mir was" title="Follow us on Facebook wünsch mir was" width="100%"/>
            </a>
        </div>
    </div>

    <div class="icon-container">
        <div class="icon">
            <a href="https://www.instagram.com/wuenschmirwas.at/" target="_new">
            <img src="assets/img/instagram_icon.png" alt="Follow us on Instagram wünsch mir was" title="Follow us on Instagram wünsch mir was" width="100%"/>
            </a>
        </div>
    </div>


    <h2>Kontaktdaten</h2>
    <h3>wünsch mir was - ehrenamtlicher Verein zur Erfüllung famili&auml;rer (Herzens-)w&uuml;nsche</h3>
    <a href="mailto:office@wuenschmirwas.at?subject=Wuensch%20mir%20was%20Kontakt" style="font-size: 1rem;">
        office@wuenschmirwas.at
    </a>

    <h2>Ansprechpartner</h2>
    <h3>Region<br> Weststeiermark | Lieboch</h3>
    <p><a href="https://m.me/tatjana.schiffer.7" target="_new">Tatjana Schiffer Wieser</a></p>

    <h3>Region<br> Graz & Graz-Umgebung</h3>
    <p><a href="https://m.me/joeyleinchen" target="_new">Tamara Schiffer</a></p>

    <h3>Region<br> Weststeiermark</h3>
    <p><a href="https://m.me/martina.kollegger" target="_new">Martina Schmidbauer</a></p>

</div>

