import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
declare var angular: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})



export class HomeComponent implements OnInit {

  facebook_width : number = 400;
  
  facebookurlSafe: SafeResourceUrl | undefined;

  constructor(public sanitizer: DomSanitizer) {
   }

  ngOnInit() {

    this.refresh_facebook();
  }

  refresh_facebook() {
    var facebook_link : string = "https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fwuenschmirwas.at%2F&tabs=timeline&width="+this.facebook_width+"&height=1000&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId";
    this.facebookurlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(facebook_link);
    console.log(this.facebook_width);
    console.log(facebook_link);
  }

  @ViewChild('facebook_content')
  facebook_content: ElementRef | undefined;

  ngAfterViewInit() {
    var width = this.facebook_content?.nativeElement.offsetWidth;
    this.facebook_width = width - 50;
    this.refresh_facebook();
  }



}

