

  
<p class="headline-content">Die Geschichte von wünsch mir was</p>
<p>Die Geschichte von „wünsch mir was“ entstand beim Zusammensitzen bei einem Kaffee. Die Gründerin Tatjana und ihre Arbeitskollegin Roswitha überlegten sich ein Projekt, mit dem sie den Kindern im Kindergarten, in dem sie gearbeitet haben, helfen können. Die Kinder in diesem Kindergarten hatten leider nicht mal das Nötigste und auch in der Einrichtung selbst fehlte einiges.</p>
<p>Der Name für das Projekt wurde in kurzer Zeit vergeben: "wünsch mir was". Beide Initiatoren waren der Meinung, dass dieser Name passen würde, denn das Projekt soll jedem Kind in Österreich helfen und die Wünsche dieser verwirklichen.</p>
<p>"wünsch mir was" war zu dieser Zeit nur ein Projekt, geleitet von Privatpersonen, die etwas Gutes tun wollen. Neben der Gründerin Tatjana kamen im Laufe der Zeit zwei weitere Personen hinzu und wurden zu Organisatorinnen. Gemeinsam organisieren wir das gesamte Projekt und beschenken mittlerweile über 100 Kinder in ganz Österreich. </p>
<p>Das Projekt wurde bis zum Sommer 2021 als dieses geführt. Seit August 2021 handelt es sich hierbei um einen offiziellen Verein mit dem Namen „wünsch mir was - ehrenamtlicher Verein zur Erfüllung familiärer (Herzens-)wünsche“. Gründerin dieses Vereins ist die Initiatorin Tatjana Schiffer-Wieser. Gemeinsam mit ihrer Schwester Tamara und ihrer Freundin Martina werden dieselben Werte, Vorstellungen und Wünsche wie auch schon zu Projektzeiten verfolgt.</p>
<p>Nachfolgend findet ihr unsere Geschichte. Alles begann im Jahr 2014 mit der Beschenkung des Kindergartens und wurde im Laufe der Zeit immer größer und auch besser. Du möchtest wissen, wie? Lies‘ los!</p>


<h1>Im Laufe der Jahre</h1>


<!--2014-->
<div class="accordion accordion-flush" id="accordionFlushExample">
    <div class="accordion-item">
      <h2 class="accordion-header" id="flush-heading2014">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse2014" aria-expanded="false" aria-controls="flush-collapse2014">
          <div class="year">2014</div>
          <div class="description">Wie alles begann</div>
        </button>
      </h2>
      <div id="flush-collapse2014" class="accordion-collapse collapse" aria-labelledby="flush-heading2014" data-bs-parent="#accordionFlushExample">
        <div class="accordion-body">
            <p>
              Das Projekt startete im Jahr 2014. Der private Kindergarten, in dem die beiden Initiatoren arbeiteten, wurde mit Spenden unterstützt. Viele Unterstützer*innen, meist Freund*innen und Familienmitglieder, kauften Dinge wie Bälle, Spiele und Dinge, die man für die Gymnastik im Kindergarten braucht. Das Unternehmen EFS, das von Anfang an zu den Unterstützern zählte, bezahlte einen Ausflug in den Zoo Herberstein für alle Kinder und für die Erzieherinnen des Kindergartens.            </p>
        </div>
      </div>
    </div>
</div>

<!--2015-->
<div class="accordion accordion-flush" id="accordionFlushExample">
    <div class="accordion-item">
      <h2 class="accordion-header" id="flush-heading2015">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse2015" aria-expanded="false" aria-controls="flush-collapse2015">
          <div class="year">2015</div>
          <div class="description">Start der personalisierten Geschenke</div>
        </button>
      </h2>
      <div id="flush-collapse2015" class="accordion-collapse collapse" aria-labelledby="flush-heading2015" data-bs-parent="#accordionFlushExample">
        <div class="accordion-body">
            <p>
              Im zweiten Jahr, 2015, begannen die Helferleins – jene Privatpersonen, die das Projekt mit Sachspenden unterstützten - nach dem Projekt zu fragen. Die Initiatoren erwarteten diese Unterstützung nicht und begannen mit Zustimmung der Kindergartenleitung so etwas wie "Weihnachten im Schuhkarton". Das bedeutet, dass jedes Kind ein Geschenk in einem Schuhkarton mit etwas Besonderem erhielt. Die Initiatorinnen wussten, dass die meisten Kinder normale Dinge wie Handschuhe, eine Haube oder einfach nur etwas Warmes zum Anziehen brauchten. Also schrieben sie eine Liste mit allen Namen der Kinder, dessen Alter und was sie brauchen oder was sie sich vom Christkind wünschen würden. Jede*r Unterstützer*in erhielt die Daten eines Kindes oder auf Wunsch von mehreren Kindern.            </p>
            <p>
              Diese Art der Hilfe für Kinder wurde in dieser Form zum ersten Mal gemacht und blieb seit jeher bestehen. Durch die erhöhte Organisation, die nun benötigt wurde, stieg die Schwester von Tatjana beim Projekt ein und wurde zum fixen Mitglied. Ab diesem Moment war es auch das Herzensprojekt von Tamara.
            </p>

        </div>
      </div>
    </div>
</div>

<!--2016 -->
<div class="accordion accordion-flush" id="accordionFlushExample">
    <div class="accordion-item">
      <h2 class="accordion-header" id="flush-heading2016">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse2016" aria-expanded="false" aria-controls="flush-collapse2016">
          <div class="year">2016</div>
          <div class="description">Ein weiteres Jahr im Kindergarten</div>
        </button>
      </h2>
      <div id="flush-collapse2016" class="accordion-collapse collapse" aria-labelledby="flush-heading2016" data-bs-parent="#accordionFlushExample">
        <div class="accordion-body">
            <p>
              Im dritten Jahr, 2016, ging „Weihnachten im Schuhkarton" weiter und die rund 25 Kinder des Kindergartens erhielten persönliche Geschenke. Mit zunehmender Beliebtheit des Projekts stiegen die Sachspenden rasch an. Durch den Kontakt zur Organisation "affido Österreich" konnten diese ihren Zweck erfüllen.
            </p>
        </div>
      </div>
    </div>
</div>


<!--2017 -->
<div class="accordion accordion-flush" id="accordionFlushExample">
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-heading2017">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse2017" aria-expanded="false" aria-controls="flush-collapse2017">
        <div class="year">2017</div>
        <div class="description">Das Projekt veränderte sich</div>
      </button>
    </h2>
    <div id="flush-collapse2017" class="accordion-collapse collapse" aria-labelledby="flush-heading2017" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">
            <p>Im vierten Jahr, 2017, hat sich das Weihnachtsprojekt grundlegend verändert und ist damit gewachsen.</p>
            <p>Der Kindergarten, der bisher beschenkt wurde, entschied, dass das Projekt nicht auf die gleiche Weise fortgesetzt werden kann, da jedes Kind das gleiche Geschenk erhalten sollte. Jedoch sind wir der Meinung, dass das Christkind nur persönliche Geschenke bringt. Durch diese Meinungsverschiedenheit ging die Zusammenarbeit mit dem Kindergarten leider zu Ende.</p>
            <p>Glücklicherweise konnten die Organisatorinnen durch einen guten Kontakt zu der Organisationen "affido Österreich" eine neue Zusammenarbeit starten. Dadurch erhielten mehr als 32 Kinder persönliche Geschenke. Diese beschenkten Kinder leiden zum Teil an Herzerkrankungen oder stammen aus Pflegefamilien.</p>
      </div>
    </div>
  </div>
</div>


<!--2018 -->
<div class="accordion accordion-flush" id="accordionFlushExample">
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-heading2018">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse2018" aria-expanded="false" aria-controls="flush-collapse2018">
        <div class="year">2018</div>
        <div class="description">Die Unterstützung stieg</div>
      </button>
    </h2>
    <div id="flush-collapse2018" class="accordion-collapse collapse" aria-labelledby="flush-heading2018" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">
        <p>
          Seit der Zusammenarbeit mit der Organisation stieg die Anfrage an Unterstützung durch unsere Helferleins und das Projekt wuchs dadurch. Im Jahr 2018 ‚explodierte‘ das Projekt und es konnten gesamt 110 Kinder beschenkt werden.
        </p>      
      </div>
    </div>
  </div>
</div>


<!--2019 -->
<div class="accordion accordion-flush" id="accordionFlushExample">
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-heading2019">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse2019" aria-expanded="false" aria-controls="flush-collapse2019">
        <div class="year">2019</div>
        <div class="description">wünsch mir was wird zum Osterhasen</div>
      </button>
    </h2>
    <div id="flush-collapse2019" class="accordion-collapse collapse" aria-labelledby="flush-heading2019" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">
        <p>
          Im Jahr 2019 startete zum ersten Mal in der Geschichte von "wünsch mir was" eine neue persönliche Geschenkidee. Durch die Zusammenarbeit mit der Facebook-Gruppe "Gemeinsam sind wir stark! - Österreich (Herz für Familien in Not)" hatte die Gründerin die Idee Helferleins des Osterhasen zu werden. Zur Freude des gesamten "wünsch mir was" Teams erhielten 70 Kinder ein persönliches Ostergeschenk.
        </p>
        <p>
          Die Weihnachtsaktion wurde weiterhin betrieben und die Anzahl der Kinder, die beschenkt wurden, stieg. Gesamt wurden 154 Kinder zu Weihnachten beschenkt. Die Organisation und die Verwaltung wurde größer und die Geschwister benötigten Hilfe. Eine gute Freundin, Martina, wurde zum Organisationsmitglied und zu dritt schaffen wir mehr als jemals gedacht. 
        </p>
    </div>
    </div>
  </div>
</div>



<!--2020 -->
<div class="accordion accordion-flush" id="accordionFlushExample">
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-heading2020">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse2020" aria-expanded="false" aria-controls="flush-collapse2020">
        <div class="year">2020</div>
        <div class="description">Corona und Schulstart</div>
      </button>
    </h2>
    <div id="flush-collapse2020" class="accordion-collapse collapse" aria-labelledby="flush-heading2020" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">
        <p>
          Das Jahr 2020 wird allen Menschen auf der Welt in Gedächtnis bleiben, denn ein globaler Virus legte die gesamte Welt lahm. Corona oder Covid19 verwandelte den Globus im März 2020 in einen stillen Ort. Raus zu gehen war nicht gewünscht und teilweise gefürchtet. Aus diesem Grund entschieden wir uns – mit Tränen in den Augen – die Osteraktion abzusagen. Wir konnten von unseren Helferlein nicht verlangen, dass sie in einer globalen Pandemie einkaufen gehen (nur lebensnotwendige Geschäfte hatten geöffnet) und Geschenke zu packen. Wir, das Team von „wünsch mir was“ waren am Boden zerstört. Wir schworen uns, dass wir hierfür eine andere Aktion starten würden. 
        </p>
        <p>
          Da sich die Lage über den Sommer beruhigte und Tatjana weiterhin einen guten Kontakt zu den Administrator*innen der Facebook-Gruppe „Gemeinsam sind wir stark – Österreich“ pflegte, entschieden wir uns dazu, eine Schulstarter-Aktion zu starten. Mit dieser Aktion möchten wir Schulstarter*innen, die die Schule neu beginnen oder die Schule wechseln (von Volksschule ins Gymnasium oder Neue Mittelschule) unterstützen und ihnen alles für den Schulstart zu Verfügung stellen. Die grundlegenden Schulsachen, die Kinder benötigen, sind nicht billig und wir möchten mit dieser neuen Aktion Familien unterstützen und Kindern die Freude bringen. 
        </p>
        <p>
          Im ersten Jahr der Schulstarter-Aktion statteten wir 11 Kinder mit allen benötigten – und teilweise mehr - Schulmaterialien aus. Durch den Erfolg dieser Aktion und der Nachfrage der Helferleins beschlossen wir, dass wir diese Aktion jährlich durchführen werden. 
        </p>
        <p>
          In der Weihnachtszeit hatte sich die Corona-Situation leider wieder verschlimmert und wir bangten um unsere Weihnachtsaktion. Die wechselnden Verschärfungen und Lockerungen durch die Regierung erleichterte uns die Entscheidung nicht, ob wir nun die Aktion durchführen sollten oder nicht. Unsere Helferleins jedoch nahmen uns die Entscheidung ab. Sie wollten etwas Gutes tun in dieser schweren Zeit und zu Weihnachten traditionell ein Kind durch unser Projekt beschenken. Durch reichlich Mundpropaganda, Empfehlungen auf Social Media und einen Beitrag in der „Kleinen Zeitung“ und im „Weekend“ Magazin stieg unsere Bekanntheit. Die Anzahl der Helferleins stieg rasant und damit auch die Anzahl an Kinder die beschenkt werden konnten. Die Anzahl am Schluss: 207! Und wir sind stolz darauf, dass geschafft zu haben. 
        </p>
    </div>
    </div>
  </div>
</div>



<!--2021 -->
<div class="accordion accordion-flush" id="accordionFlushExample">
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-heading2021">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse2021" aria-expanded="false" aria-controls="flush-collapse2021">
        <div class="year">2021</div>
        <div class="description">Noch immer Corona aber größer als zuvor</div>
      </button>
    </h2>
    <div id="flush-collapse2021" class="accordion-collapse collapse" aria-labelledby="flush-heading2021" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">
        <p>
          Die Pandemie hält uns noch immer fest im Griff, aber wir sind es mittlerweile gewöhnt. Ein Lockdown hier, Lockerungen da und die lang ersehnten Impfungen. Das alles hat uns als Projektteam jedoch absolut nicht geschwächt und die Unterstützung unserer Helferleins ist stärker als je zuvor.
        </p>
        <p>
          Die Osteraktion im Jahr 2021 war ein voller Erfolg. 163 Kinder erhielten ein kleines personalisiertes Geschenk. Durch die Zusammenarbeit mit einem Gemeindeabgeordneten in Lieboch und der Unterstützung eines Speditionsunternehmens wurde uns die Lieferung der Geschenke übernommen.
        </p>
        <p>
          Die Schulstarteraktion ging in die zweite Runde und wir konnten 30 Kinder der Organisation „Herzkinder Österreich“ und der Facebook-Gruppe „Gemeinsam sind wir stark! - Österreich (Gratisgruppe - Österreich)“ mit allem ausstatten, dass für den Schulstart benötigt wird.
        </p>
        <p>
          Bevor wir mit unserer Weihnachtsaktion beginnen konnten, erhielten wir die Möglichkeit unseren Verein in der Gemeindezeitung Lieboch vorzustellen. Uns wurden zwei Seiten zu Verfügung gestellt, die wir frei gestalten durften. Dadurch konnten wir neue Helferleins akquirieren und unsere Weihnachtsaktion größer planen.
        </p>
        <p>
          Auch gegen Ende des Jahres 2021 war Corona noch immer ein Thema, doch unsere Helferleins hatten noch mehr Freude und Lust darauf, Kinderaugen zum Strahlen zu bringen, als je zuvor. Aus diesem Grund konnten wir insgesamt 240 Kinder zu Weihnachten beschenken. Gemeinsam mit den Organisationen „Herzkinder Österreich“, „affido Österreich“, der Facebook-Gruppe „Gemeinsam sind wir stark! – Österreich (Gratisgruppe – Österreich)“ und einer Frühförderin aus Graz, konnten wir dies möglich machen.
        </p>
        <p>
          Zudem konnten wir erstmalig mit Sachspenden, die an uns übergeben wurden, weitere Kinderaugen zum Leuchten bringen. Wir haben diese an die Kinderklinik des LKH Graz übergeben können. Dort werden sie für Weihnachten aber auch für Geburtstage in dieser Zeit eingesetzt.
        </p>
    </div>
    </div>
  </div>
</div>




<h1>Zukunft bei &bdquo;wünsch  mir was&ldquo;</h1>
<p>
  Wenn man die Schwestern Tatjana und Tamara fragt, wird es kein Ende geben. In Zukunft wird der Verein mehr Organisationen unterstützen und mehr Kinder beschenken, als je gedacht. Die Aktionen werden wie bisher durchgeführt beibehalten werden. Unsere Arbeit wird also weiterhin vor allem zu Ostern, zum Schulstart und zu Weihnachten erfolgen. Ob eine weitere Aktion in Zukunft kommt, können wir noch nicht sagen, aber falls du eine Idee dafür hast, schreib uns gerne.
</p>
<p>
  Wir, das Team von wünsch mir was hat nie mit so einer Reichweite gerechnet, doch: „Wünsche werden wahr, und wünsch mir was wird mehr als nur ein Wunsch!“
</p>


