import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { BannerComponent } from './banner/banner.component';
import { SidebarComponent } from './content/sidebar/sidebar.component';
import { HomeComponent } from './content/home/home.component';
import { GeschichteComponent } from './content/geschichte/geschichte.component';
import { TeamComponent } from './content/team/team.component';
import { UnterstuetzerComponent } from './content/unterstuetzer/unterstuetzer.component';
import { HelferleinComponent } from './content/helferlein/helferlein.component';
import { FormConformationComponent }  from './content/helferlein/helferlein.component';
import { AktionenComponent } from './content/aktionen/aktionen.component';
import { DatenschutzComponent } from './content/datenschutz/datenschutz.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete'; 
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatRadioModule} from '@angular/material/radio';
import {MatCheckboxModule} from '@angular/material/checkbox'; 
import {MatSelectModule} from '@angular/material/select';
import {MatBadgeModule} from '@angular/material/badge';
import {MatSnackBarModule} from '@angular/material/snack-bar';


import { FooterComponent } from './footer/footer.component';
import { ImpressumComponent } from './content/impressum/impressum.component';
import { PresseComponent } from './content/presse/presse.component'; 


import {MatTabsModule} from '@angular/material/tabs'; 
import {NgcCookieConsentModule, NgcCookieConsentConfig} from 'ngx-cookieconsent';


const cookieConfig:NgcCookieConsentConfig = 
{
  "cookie": {
    "domain": "wuenschmirwas.at"
  },
  "position": "bottom-right",
  "theme": "classic",
  "palette": {
    "popup": {
      "background": "#8a7491",
      "text": "#ffffff",
      "link": "#ffffff"
    },
    "button": {
      "background": "#f1d600",
      "text": "#000000",
      "border": "transparent"
    }
  },
  "type": "info",
  "content": {
    "message": "Um unsere Webseite für Sie optimal zu gestalten und fortlaufend verbessern zu können, verwenden wir Cookies. \nWeitere Informationen zu Cookies erhalten Sie in unserer",
    "dismiss": "Ok",
    "link": "Datenschutz",
    "href": "#/datenschutz",
  }
}
;

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    BannerComponent,
    SidebarComponent,
    HomeComponent,
    GeschichteComponent,
    TeamComponent,
    UnterstuetzerComponent,
    HelferleinComponent,
    FormConformationComponent,
    AktionenComponent,
    FooterComponent,
    ImpressumComponent,
    PresseComponent,
    DatenschutzComponent
  ],
  imports: [
    NgcCookieConsentModule.forRoot(cookieConfig),
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule, ReactiveFormsModule, MatAutocompleteModule,
    MatInputModule, MatButtonModule, MatRadioModule, MatCheckboxModule, MatSelectModule, MatBadgeModule, MatSnackBarModule, 
    MatTabsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
