
<span class="form-confirmation">
    <p class="form-confirmation">Vielen Dank für deine Anmeldung!</p>    
    <p class="form-confirmation">Wir freuen uns, dich als Helferlein begrüßen zu dürfen.</p>
    <p class="form-confirmation">In Kürze wird sich ein Teammitglied bei dir melden.</p>
    <br><br>
    <p class="confirm_add">
        Wir sind auf deine angegebenen Kontaktinformationen angewiesen. Falls sich diese ändern sollte, lass uns das bitte umgehend wissen. Bitte checke auch deinen Spam-Ordner, ob Nachrichten von uns eingelangt sind und setze unsere Mailadresse auf die White-List. 
    </p>
    
    <button mat-raised-button color="primary" (click)="dismiss()">OK</button>
</span>


