
<p class="headline-content">wünsch mir was</p>
<table width="97%" border="0" align="center" cellspacing="5">
    <tr valign="top">
      <td colspan="2" align="left"><p>Karitatives Engagement kann so einfach sein: Organisation / Verein wählen, Geld überweisen... Fertig! Das Gewissen ist beruhigt. Also zurücklehnen und stolz auf die gute Tat sein. Bei uns läuft das ein wenig anders. Bei uns sind deine Taten gefragt. </p>

        <p>Du möchtest gerne ein Kind beschenken? Du möchtest involviert werden, was das Kind erhält? Du möchtest Kinderaugen zu besonderen Anlässen, wie Ostern / Weihnachten etc. zum Strahlen bringen? </p>
        
        <p>Dann herzlich willkommen bei wünsch mir was. Bei uns wirst du als Helferlein involviert und bist für die gesamte Abwicklung - von Geschenk kaufen bis Geschenk übergeben - selbst verantwortlich. Einfach mal Geld überweisen? Geht natürlich auch, aber das macht nur halb so viel Spaß.  </p>
        
        <h1>Wie sieht der Ablauf unserer Aktionen aus?</h1>

        <p class="bolder-text">Zu aller erst ist deine Registrierung notwendig. </p>
        <p>Dafür klickst du einfach auf den Button oben oder hier auf <a routerLink="/helferlein-werden">"Helferlein werden"</a>. So wirst du in wenigen Minuten zu einem Helferlein und bist somit ein*e Helfer*in und wirst von uns kontaktiert.</p>
        <p>Bei der nächsten (oder gewünschten) Aktion erhältst du von uns die Daten eines Kindes zugesendet. Diese Daten enthalten den Vornamen, das Alter, gegebenenfalls die Kleidungsgröße und den Wunsch, den das Kind geäußert hat. Jetzt bist du am Zug: du besorgst das Geschenk, verpackst es liebevoll und übergibst es einem von uns (mehr Infos zu uns findest du auf unserer <a routerLink="/team">Teamseite</a>). Wir schicken dann das Paket auf die Reise, damit es pünktlich zu dem besonderen Anlass beim Kind ist und Kinderaugen zum Strahlen bringt.</p>
        
        <p class="bolder-text">Du findest das hört sich alles toll an?</p>
        <p>Dann registriere dich bei uns als Helferlein und hilf uns noch mehr Kinderaugen in ganz Österreich zum Leuchten zu bringen. </p>

        <p class="bolder-text">Du willst mehr von uns erfahren? </p>
        <p>Dann schau dir unsere <a routerLink="/aktionen">Aktionen</a> an, wo wir all unsere bisherigen Tätigkeiten aufgelistet haben. Unsere <a routerLink="/geschichte">Geschichte</a> zeigt dir, wie wir es vom kleinen Projekt zu einem wunderbaren Verein geschafft haben. Unter <a routerLink="/presse">Presse & Publikationen</a> findest du unsere Veröffentlichungen. Wir haben es nämlich schon in die Zeitung geschafft und sind wirklich stolz darauf. </p>
        
        <p class="bolder-text">Du findest du kannst uns noch besser unterstützen?</p>
        <p>Unter <a routerLink="/unterstuetzer">Unterstützer/innen</a> findest du alle Unternehmen, die uns bisher auf unserem Weg begleitet haben und ohne deren Unterstützung wir nicht so durchstarten hätten können. Wenn auch dein Unternehmen diesen Beispielen folgen möchte, dann schreib uns gerne eine <a href="mailto:office@wuenschmirwas.at">E-Mail.</a> </p>

        <p class="bolder-text">Sind noch Fragen offen? </p>
        <p>Dann hau in die Tasten und schreib uns eine <a href="mailto:office@wuenschmirwas.at">Email</a>, oder eine <a href="https://m.me/wuenschmirwas.at">Facebook Nachricht</a>, oder eine <a href="https://www.instagram.com/wuenschmirwas.at/">Instagram Message</a> ODER du schickst eine Brieftaube auf den Weg. Egal wie, wir freuen uns von dir zu lesen, zu hören und dich als Helferlein begrüßen zu dürfen.</p>
      </td>
    </tr>
</table>






<div class="row" >
  <div #facebook_content class="col-lg-6" style="margin: 0 auto;">
    <h2>Facebook</h2>
    <div class="bs-example" style="margin: 0 auto;">
      <div class="embed-responsive embed-responsive-16by9">
        <iframe class="embed-responsive-item" height="1000px" [src]="facebookurlSafe" style="border:none;overflow:hidden; width: 100%;" scrolling="no" 
        frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
      </div>
    </div>
  </div>
  <div class="col-lg-6" style="margin: 0 auto;">
    <h2>Instagram</h2>
    <div class="bs-example" style="margin: 0 auto;">
      <div class="embed-responsive embed-responsive-16by9">

      <!-- SnapWidget -->
      <script src="https://snapwidget.com/js/snapwidget.js"></script>
      <iframe src="https://snapwidget.com/embed/955376" height="1000px" class="embed-responsive-item snapwidget-widget" allowtransparency="true" frameborder="0" scrolling="no" style="border:none; overflow:hidden;  width:100%; "></iframe>


      </div>
    </div>
  </div>
</div>


<!--
<div class="row socialmedia">
  <div class="col socialmedia_column">
    <h2>Facebook</h2>

    
    <iframe [src]="facebookurlSafe"
      [width]="facebook_width" height="1000" style="border:none;overflow:hidden" scrolling="no" 
      frameborder="0" allowfullscreen="true" 
      allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share">
    </iframe>
    
  </div>
  <div class="col socialmedia_column">
    <h2>Instagram</h2>
    <iframe style="background-color: cadetblue;"></iframe>
  </div>
-->

