import { Component, OnInit, HostListener } from '@angular/core';


@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  
  scrolled : boolean = false;

  constructor() { }

  @HostListener('window:scroll', ['$event'])
  handleScroll() {
    if(window.scrollY < 70) {
      this.scrolled = false;
    }
    else {
      this.scrolled = true;
    }
}


  ngOnInit(): void {
  }

}
