<p class="headline-content">Datenschutz und Cookie Verwaltung</p>

<h2>1. Allgemeines zur Datenverarbeitung</h2>

<p>Wir verarbeiten personenbezogene Daten unserer Nutzer*innen grundsätzlich nur, soweit dies zur Bereitstellung einer funktionsfähigen Website sowie unserer Inhalte und Leistungen erforderlich ist. Die Verarbeitung personenbezogener Daten unserer Nutzer*innen erfolgt regelmäßig nur nach Einwilligung des Nutzers * der Nutzerin . Eine Ausnahme gilt in solchen Fällen, in denen eine vorherige Einholung einer Einwilligung aus tatsächlichen Gründen nicht möglich ist und die Verarbeitung der Daten durch gesetzliche Vorschriften gestattet ist.</p>

<h2>2. Verwendung von Cookies</h2>

<p>Wir setzen Cookies ein, um unsere Website nutzerfreundlicher zu gestalten. Einige Elemente unserer Internetseite erfordern es, dass der aufrufende Browser auch nach einem Seitenwechsel identifiziert werden kann.</p>

<p>In den Cookies werden dabei folgende Daten gespeichert und übermittelt:</p>

<ul>
  <li>Spracheinstellungen</li>
</ul>

<p>Wir verwenden auf unserer Website darüber hinaus Cookies, die eine Analyse des Surfverhaltens der Nutzer*innen ermöglichen.</p>

<ul>
  <li>Eingegebene Suchbegriffe</li>
  <li>Häufigkeit von Seitenaufrufen</li>
  <li>Inanspruchnahme von Website-Funktionen</li>
</ul>

<p>Die auf diese Weise erhobenen Daten der Nutzer*innen werden durch technische Vorkehrungen pseudonymisiert. Daher ist eine Zuordnung der Daten zum aufrufenden Nutzer * zur aufrundenden Nutzerin nicht mehr möglich. Die Daten werden nicht gemeinsam mit sonstigen personenbezogenen Daten der Nutzer*innen gespeichert.</p>

<h2>3. Kontaktformular und E-Mail-Kontakt</h2>

<p>Auf unserer Internetseite ist ein Kontaktformular vorhanden, welches für die elektronische Kontaktaufnahme genutzt werden kann. Nimmt ein Nutzer * eine Nutzerin diese Möglichkeit wahr, so werden die in der Eingabemaske eingegeben Daten an uns übermittelt und gespeichert. Diese Daten sind:</p>

<ul>
  <li>E-Mailadresse</li>
  <li>Name (wenn angegeben)</li>
  <li>Die IP-Adresse des Nutzers * der Nutzerin</li>
  <li>Datum und Uhrzeit der Registrierung</li>
</ul>

<p>Alternativ ist eine Kontaktaufnahme über die bereitgestellte E-Mail-Adresse (<a href="mailto:office@wuenschmirwas.at">office@wuenschmirwas.at</a>) möglich. In diesem Fall werden die mit der E-Mail übermittelten personenbezogenen Daten des Nutzers * der Nutzerin gespeichert.</p>

<p>Es erfolgt in diesem Zusammenhang keine Weitergabe der Daten an Dritte. Die Daten werden ausschließlich für die Verarbeitung der Konversation verwendet.
</p>

<p>Der Nutzer * Die Nutzerin hat jederzeit die Möglichkeit, seine Einwilligung zur Verarbeitung der personenbezogenen Daten zu widerrufen. Nimmt der Nutzer * die Nutzerin per E-Mail Kontakt mit uns auf, so kann er * sie der Speicherung seiner * ihrer personenbezogenen Daten jederzeit widersprechen. In einem solchen Fall kann die Konversation nicht fortgeführt werden.
</p>

<h2>5. Google Analytics</h2>

<p>Soweit Sie Ihre Einwilligung erklärt haben, wird auf dieser Website Google Analytics eingesetzt, ein Webanalysedienst der Google LLC („Google“). Die Nutzung umfasst die Betriebsart „Universal Analytics“. Hierdurch ist es möglich, Daten, Sitzungen und Interaktionen über mehrere Geräte hinweg einer pseudonymen User-ID zuzuordnen und so die Aktivitäten eines Nutzers * einer Nutzerin geräteübergreifend zu analysieren. Dieser Datenschutzhinweis wird zur Verfügung gestellt von www.intersoft-consulting.de.
</p>

<p>Google Analytics verwendet sog. „Cookies“, Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch das Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Im Falle der Aktivierung der IP-Anonymisierung auf dieser Website, wird Ihre IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Wir weisen Sie darauf hin, dass auf dieser Webseite Google Analytics um eine IP-Anonymisierung erweitert wurde, um eine anonymisierte Erfassung von IP-Adressen (sog. IP-Masking) zu gewährleisten. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt. Nähere Informationen zu Nutzungsbedingungen und Datenschutz finden Sie unter https://www.google.com/analytics/terms/de.html bzw. unter https://policies.google.com/?hl=de.
</p>

<h2>Zwecke der Verarbeitung</h2>

<p>Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Websitebetreiber zu erbringen.
</p>


<h2>Rechtsgrundlage</h2>

<p>Die Rechtsgrundlage für den Einsatz von Google Analytics ist Ihre Einwilligung gemäß Art. 6 Abs. 1 S.1 lit. a DSGVO.
</p>

<h2>Empfänger / Kategorien von Empfängern</h2>

<p>Der Empfänger der erhobenen Daten ist Google.</p>


<h2>Übermittlung in Drittstaaten</h2>

<p>Die personenbezogenen Daten werden unter dem EU-US Privacy Shield auf Grundlage des Angemessenheitsbeschlusses der Europäischen Kommission in die USA übermittelt. Das Zertifikat können Sie hier abrufen.
</p>

<h2>Dauer der Datenspeicherung</h2>

<p>Die von uns gesendeten und mit Cookies, Nutzerkennungen (z. B. User-ID) oder Werbe-IDs verknüpften Daten werden nach 14 Monaten automatisch gelöscht. Die Löschung von Daten, deren Aufbewahrungsdauer erreicht ist, erfolgt automatisch einmal im Monat.
</p>

<h2>IP Anonymisierung</h2>

<p>Wir weisen Sie darauf hin, dass auf dieser Website Google Analytics um den Code „anonymizeIp“ erweitert wurde, um eine anonymisierte Erfassung von IP-Adressen (sog. IP-Masking) zu gewährleisten.
</p>

<h2>Betroffenenrechte</h2>

<p>Sie können Ihre Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen, indem Sie die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können.
</p>

<p>Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie das Browser-Add-on herunterladen und installieren. Opt-Out-Cookies verhindern die zukünftige Erfassung Ihrer Daten beim Besuch dieser Website. Um die Erfassung durch Universal Analytics über verschiedene Geräte hinweg zu verhindern, müssen Sie das Opt-Out auf allen genutzten Systemen durchführen. Wenn Sie hier klicken, wird das Opt-Out-Cookie gesetzt: Google Analytics deaktivieren
</p>

<h2>6. Facebook</h2>

<p>Verwendung von Facebook-Plugins. Unsere Website enthält Plugins von Facebook, 1601 South California Avenue, Palo Alto, CA 94304, USA. Facebook ist ein soziales Netzwerk. Das jeweilige Plugin ist am Logo von Facebook, Instagram oder dem „Gefällt-mir-Button“ erkennbar.
</p>

<p>Sobald Sie unsere Website frequentieren, stellt das Facebook-Plugin zwischen Ihrem Internetbrowser und den Servern von Facebook eine unmittelbare Verbindung her. Auf diesem Weg wird Facebook darüber informiert, dass mit Ihrer IP-Adresse unsere Website besucht wurde. Für den Fall, dass Sie bei Facebook oder Instagram eingeloggt sein sollten, können Sie mittels des „Gefällt-mir-Buttons“ den entsprechenden Inhalt, der sich auf unserer Website befindet, in Ihrem Profil bei Facebook oder Instagram verlinken. Es ist Facebook dann möglich, Ihren Besuch unserer Website Ihrem Facebook- oder Instagram-Account zuzuordnen. Wir, als Anbieter unserer Website, werden von Facebook nicht über den Inhalt der übertragenen Daten beziehungsweise der Datennutzung informiert. Unter dem nachfolgenden Link können Sie sich weitergehend informieren: de-de.facebook.com/policy.php
</p>

<p>Für den Fall, dass Sie Mitglied von Facebook oder Instagram sein sollten, jedoch nicht möchten, dass Facebook über unsere Internetseite Daten über Sie übermittelt bekommt und mit Ihren Mitgliedsdaten verbindet, so müssen Sie sich, bevor Sie unsere Internetseite aufrufen, bei Facebook und Instagram ausloggen.
</p>

<h2>7. Ihre Rechte</h2>

<p>Ihnen stehen bezüglich Ihrer bei uns gespeicherten Daten grundsätzlich die Rechte auf Auskunft, Berichtigung, Löschung, Einschränkung, Datenübertragbarkeit, Widerruf und Widerspruch zu. Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden sind, können Sie sich bei uns oder der Datenschutzbehörde beschweren.
</p>
