<p class="headline-content">Impressum</p>
<p>Informationspflicht  laut §5 E-Commerce Gesetz, §14 Unternehmensgesetzbuch, §63 Gewerbeordnung und  Offenlegungspflicht laut §25 Mediengesetz.</p>
<p>Alle  Inhalte und Werke, die auf dieser Website veröffentlicht sind, sind  urheberrechtlich geschützt. Jegliche Art der Vervielfältigung, Bearbeitung,  Verbreitung und jede andere Art der Verwertung bedarf der ausdrücklichen  Zustimmung eines Mitglieds des wünsch mir was Teams. Die Bilderrechte liegen  bei den jeweilig angeführten FotografInnen sowie GrafikerInnen. Wenn nicht  anders deklariert, liegen die Bilderrechte beim Team von wünsch mir was. </p>
<p>Wir  entwickeln die Inhalte dieser Webseite ständig weiter und bemühen uns korrekte  und aktuelle Informationen bereitzustellen. Leider können wir keine Haftung für  die Korrektheit aller Inhalte auf dieser Webseite übernehmen. Sollten dir  problematische oder rechtswidrige Inhalte auffallen, bitten wir dich uns  umgehend zu kontaktieren. Du findest die Kontaktdaten im Impressum.</p>
<p>Unsere  Webseite enthält Links zu anderen Webseiten für deren Inhalt wir nicht  verantwortlich sind. Haftung für verlinkte Websites besteht laut <a href="https://www.ris.bka.gv.at/Dokument.wxe?Abfrage=Bundesnormen&amp;Dokumentnummer=NOR40025813&amp;tid=221105627"  target="_new" class="intern_link">§ 17 ECG</a> für uns nicht, da wir keine  Kenntnis rechtswidriger Tätigkeiten hatten und haben, uns solche  Rechtswidrigkeiten auch bisher nicht aufgefallen sind und wir Links sofort  entfernen würden, wenn uns Rechtswidrigkeiten bekannt werden.<br />
Wenn dir  rechtswidrige Links auf unserer Website auffallen, bitten wir dich uns zu  kontaktieren. Du findest die Kontaktdaten nachstehend.<br />
<br />
Name der  Medieninhaberinnen: Tatjana Schiffer-Wieser und Tamara Schiffer, BA<br />
Sitz der Vereins: 8501 Lieboch<br />
Email: <a href="mailto:office@wuenschmirwas.at" class="intern_link">office@wuenschmirwas.at</a></p>
<p>Die  Gestaltung dieser Website unterliegt Herrn David Kerschbaumer im Auftrag von  wünsch mir was. Der Webhost sowie die Domain sind Eigentum der SysUp OG und wurden dem Verein wünsch mir was kostenfrei zu Verfügung gestellt.</p>
