<p class="headline-content">Unsere Aktionen in den letzten Jahren</p>


<p>Unser Projekt startete im Jahr 2014 und ist seither gewachsen und wird immer größer. Im Jahr 2021 gründeten wir einen Verein, um unsere Aktionen noch größer gestalten zu können. Um dir eine kleine Übersicht zu geben, was wir bisher erreicht haben, findest du hier eine Übersicht über unsere bisherigen Aktionen. Die Geschichte zu den jeweiligen Aktionen findest du unter <a routerLink="/geschichte">Geschichte</a>.</p>

<div class="container">
  <div id="content">
    <div id="main-content">
      <mat-tab-group>

        <mat-tab>
          <ng-template mat-tab-label>
            <img src="assets/img/aktionen/weihnachtsaktion2022.png" style="width: 100%; height: 100%">
            <!--<span>First</span>-->
          </ng-template>
          <h2>Weihnachtsaktion 2022</h2>
          <p>Die Weihnachtsaktion 2022 wurde trotz Energiekrise und Teuerungen ein voller Erfolg. Über 263 Kinder wurden zu Weihnachten beschenkt. Die Organisationen „Herzkinder Österreich“, „affido Österreich“, eine Frühförderin in Graz und erstmalig der Verein „Rainbows“ wurden hierbei unterstützt. Zudem konnten auch Geschenke in die Teddyhäuser Wien und Linz gesendet werden sowie an die Kinderstation des LKH Graz.</p>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            <img src="assets/img/aktionen/schulstarteraktion2022.png" style="width: 100%; height: 100%">
            <!--<span>First</span>-->
          </ng-template>
          <h2>Schulstarteraktion 2022</h2>
          <p>Zum Schulstart 2022 konnten wir – dank der Unterstützung unserer Helferleins – 20 Kinder mit allen notwendigen Schulsachen ausstatten. Unterstützt wurden hierbei Familien der Organisation „Herzkinder Österreich“ und des Vereins „Rainbows“.</p>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            <img src="assets/img/aktionen/osteraktion2022.png" style="width: 100%; height: 100%">
            <!--<span>First</span>-->
          </ng-template>
          <h2>Osteraktion 2022</h2>
          <p>Zu Ostern 2022 durften wir 170 Kinder mit kleinen Osterüberraschungen beschenken. Zudem haben wir auch Ostergeschenke an die Teddyhäuser Wien und Linz geschickt, um auch den Familien, die Ostern in den Teddyhäusern verbringen mussten, die Zeit zu versüßen.</p>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            <img src="assets/img/aktionen/weihnachtsaktion2021.png" style="width: 100%; height: 100%">
            <!--<span>First</span>-->
          </ng-template>
          <h2>Weihnachtsaktion 2021</h2>
          <p>Trotz anhaltender, globaler Pandemie wurde ein erneuter Rekord bei „wünsch mir was“
            aufgestellt: 240 Kinder wurden zu Weihnachten beschenkt. Die Organisationen „Herzkinder
            Österreich“, „affido Österreich“, eine Frühförderin in Graz und die Facebook-Gruppe
            „Gemeinsam sind wir stark! – Österreich (Gratisgruppe – Österreich)“ wurden hierbei
            unterstützt.</p>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            <img src="assets/img/aktionen/schulstarteraktion2021.png" style="width: 100%; height: 100%">
            <!--<span>First</span>-->
          </ng-template>
          <h2>Schulstarteraktion 2021</h2>
          <p>Das zweite Jahr, in der wir die Schulstarteraktion veranstaltet haben. Durch die Unterstützung unserer Helferleins konnten wir 30 Kinder mit allem ausstatten, dass für den Schulstart benötigt wird. Unterstützt wurden hierbei Familien der Organisation „Herzkinder Österreich“ und der Facebook-Gruppe „Gemeinsam sind wir stark! – Österreich“</p>
        </mat-tab>


        <mat-tab>
          <ng-template mat-tab-label>
            <img src="assets/img/aktionen/osteraktion2021.png" style="width: 100%; height: 100%">
            <!--<span>First</span>-->
          </ng-template>
          <h2>Osteraktion 2021</h2>
          <p>Das Osterfest im zweiten Pandemiejahr wurde für 163 Kinder strahlend durch unsere Hilfe. </p>
        </mat-tab>
      
        <mat-tab>
          <ng-template mat-tab-label>
            <img src="assets/img/aktionen/weihnachtsaktion2020.png" style="width: 100%">
          </ng-template>
          <h2>Weihnachtsaktion 2020</h2>
          <p>Die Weihnachtsaktion verlief trotz globaler Pandemie reibungslos und wir konnten einen neuen Rekord aufstellen: 207 Kinder konnten wir durch die Hilfe unserer Helferleins und Unterstützer*innen beschenken.</p>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            <img src="assets/img/aktionen/schulstarteraktion2020.png" style="width: 100%">
          </ng-template>
          <h2>Schulstarter 2020</h2>
          <p>Unsere erste Schulstarter-Aktion verlief trotz Covid19 reibungslos und 11 Kinder erhielten alles – und noch mehr – was sie zum Schulstart benötigten.</p>
        </mat-tab>
  

        <mat-tab>
          <ng-template mat-tab-label>
            <img src="assets/img/aktionen/weihnachtsaktion2019.png" style="width: 100%">
          </ng-template>
          <h2>Weihnachtsaktion 2019</h2>
          <p>Insgesamt wurden 154 Kinder beschenkt. Für die Kinder der Organisation „affido Österreich“, jene der Facebook-Gruppe "Gemeinsam sind wir stark! - Österreich (Herz für Familien in Not)" und Kinder einer Frühförderin wurde wünsch mir was zum Christkind.</p>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            <img src="assets/img/aktionen/osteraktion2019.png" style="width: 100%">
          </ng-template>
          <h2>Osteraktion 2019</h2>
          <p>Die erste Osteraktion wurde für uns zum vollen Erfolg. 70 Kinder erhielten durch unsere Hilfe ein personalisiertes Ostergeschenk.</p>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            <img src="assets/img/aktionen/weihnachtsaktion2018.png" style="width: 100%">
          </ng-template>
          <h2>Weihnachtsaktion 2018</h2>
          <p>Die Anzahl unserer Helferleins stieg und die Zusammenarbeit mit Organisationen und Facebook-Gruppen nahm zu. Gesamt wurden 110 Kinder beschenkt, aufgeteilt auf die Organisation „affido Österreich“, der Facebook-Gruppe „Gemeinsam sind wir stark – Österreich“ und Kinder einer Frühförderin.</p>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            <img src="assets/img/aktionen/weihnachtsaktion2017.png" style="width: 100%">
          </ng-template>
          <h2>Weihnachtsaktion 2017</h2>
          <p>Die Zusammenarbeit mit dem Kindergarten wurde aufgelöst und eine neue entstand. Dadurch konnten mehr als 32 Kinder der Organisation „affido Österreich“ beschenkt werden.</p>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            <img src="assets/img/aktionen/weihnachtsaktion2016.png" style="width: 100%">
          </ng-template>
          <h2>Weihnachtsaktion 2016</h2>
          <p>Das zweite Jahr von „Weihnachten im Schuhkarton“ und leider auch das letzte Jahr als Unterstützung des Kindergartens. 25 Kinder freuten sich, dass das Christkind im Kindergarten vorbeikam und ihnen personalisierte Geschenke brachte.</p>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            <img src="assets/img/aktionen/weihnachtsaktion2015.png" style="width: 100%">
          </ng-template>
          <h2>Weihnachtsaktion 2015</h2>
          <p>Der Start von personalisierten Geschenken in Form von „Weihnachten im Schuhkarton“ für die 20 Kinder des Kindergartens, der damalig unterstützt wurde. </p>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            <img src="assets/img/aktionen/weihnachtsaktion2014.png" style="width: 100%">
          </ng-template>
          <h2>Weihnachtsaktion 2014</h2>
          <p>Alles begann mit Unterstützung in einem Kindergarten und 16 Kindern, die Geschenke in Form von Sachspenden erhielten. </p>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>