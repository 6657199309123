 <nav class="navbar navbar-expand-lg navbar-light bg-light stroke" [class.fixed-top]="scrolled">
    <div class="container-fluid">
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerElement" aria-controls="navbarTogglerElement" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse navbar-color" id="navbarTogglerElement">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item ">               <!-- here for collapse after click                           -->
          <a class="nav-link" routerLink="/" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">wünsch mir was</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/geschichte" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Geschichte</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/team" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Team</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" routerLink="/unterstuetzer" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Unterstützer/innen</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/helferlein-werden" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Helferlein werden</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/aktionen" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Aktionen</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/presse" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Presse & Publikationen</a>
          </li>
      </ul>
    </div>
  </div>
</nav>    
  