<p class="headline-content">Presse und Publikationen</p>
<p>Wir, das Team von wünsch mir was, haben nie damit gerechnet, dass unser Verein so großen Anklang findet und wir jemals aus Zeitungen strahlen dürfen. Wir wurden eines besseren belehrt und wir präsentieren dir nun stolz unsere Sammlung an Presseartikeln und Publikationen.</p>


<p>Falls du uns auch einen Platz in einer Zeitung, einem Blogartikel, einer Podcast-Folge oder dergleichen zuteilwerden lassen möchtest, dann freuen wir uns über eine Mail von dir an <a href="mailto:office@wuenschmirwas.at">office@wuenschmirwas.at</a></p>


<!-- ADDING new press articles: 
  1. copy a section (container and modal) 
  2. change 2x image (in container and in modal) 
  3. change "data-bs-target" in container and 2x id in modal (1x div and 1x h2)
  4. change Title in container and modal 
  5. change text in modal
-->

<!-- Photo Grid -->
<div class="row"> 
  <div class="column">


        <!-- Medium2 BLOG -->
        <div class="article_container">
          <img src="assets/img/presse/BlogartikelMedium_29092021.jpg" style="width:100%" data-bs-toggle="modal" data-bs-target="#medium2_presse">
          <div class="title">Medium.com Blogartikel – 29.09.2021</div>
        </div>
    
        <div class="modal fade" id="medium2_presse" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-scrollable modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h2 class="modal-title" id="medium2_presseLabel">Medium.com Blogartikel – 29.09.2021</h2>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <img src="assets/img/presse/BlogartikelMedium_29092021.jpg" class="modal_img">
                <p>Unsere Organisatorin Tamara hat im Rahmen ihres Masterstudiums „Content Strategy“ an der FH Joanneum noch einen Blogartikel ihrem Herzensprojekt und ehrenamtlichen Vereins „wünsch mir was“ gewidmet. Diesmal geht es um die Projektarbeiten, die zum Schluss eines jeden Semesters anstehen. Der Relaunch der Website wurde in ihrer ersten Projektarbeit behandelt und ist nun umgesetzt worden. Wie so ein Website-Relaunch aussieht und was alles bedacht werden muss, kannst du <a href="https://tamaraschiffer.medium.com/von-einer-projektarbeit-zur-realen-umsetzung-1f84daca2575" target="_new">hier nachlesen.</a></p>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Schließen</button>
              </div>
            </div>
          </div>
        </div>

    <!-- Herzblatt 22 Jahresrückblick 2021 BLOG -->
    <div class="article_container">
      <img src="assets/img/presse/Herzblatt_Ruechblick2021.jpg" style="width:100%" data-bs-toggle="modal" data-bs-target="#herzblatt2021_presse">
      <div class="title">Herzblatt 22 - Jahresrückblick 2021</div>
    </div>

    <div class="modal fade" id="herzblatt2021_presse" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-scrollable modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="modal-title" id="herzblatt2021_presseLabel">Herzblatt 22 - Jahresrückblick 2021</h2>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <img src="assets/img/presse/Herzblatt_Ruechblick2021.jpg" class="modal_img">
            <p>Die Non-Profit Organisation „Herzkinder Österreich“ hat uns in ihrem jährlichen Magazin „Herzblatt“ erwähnt und sich somit für unsere Unterstützung im Jahr 2021 bedankt. Wir freuen uns sehr über diese Erwähnung. Im Jahresrückblick 2021 in der Ausgabe „Herzblatt 22“ findet ihr uns auf Seite 145. </p>
            <p><a href=" https://www.herzkinder.at/downloads" target="_new">Hier kannst du das Magazin „Herzblatt“ downloaden</a></p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Schließen</button>
          </div>
        </div>
      </div>
    </div>


    <!-- Medium1 BLOG -->
    <div class="article_container">
      <img src="assets/img/presse/MediumArtikel_06032021.png" style="width:100%" data-bs-toggle="modal" data-bs-target="#medium1_presse">
      <div class="title">Medium.com Blogartikel – 06.03.2021</div>
    </div>

    <div class="modal fade" id="medium1_presse" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-scrollable modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="modal-title" id="medium1_presseLabel">Medium.com Blogartikel – 06.03.2021</h2>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <img src="assets/img/presse/MediumArtikel_06032021.png" class="modal_img">
            <p>Das Studium „Content Strategy“ an der FH Joanneum in Graz verlangt von ihren Student*innen, dass sie einen persönlichen Blog eröffnen und Beiträge verfassen. </p>
            <p>Unsere Organisatorin Tamara hat dazu einen Artikel zum Thema „Die Intention, um ein soziales Projekt aufzubauen“ geschrieben und beschreibt, was ihre Motivation für „wünsch mir was“ ist.</p> 
            <p><a href="https://tamaraschiffer.medium.com/the-intention-to-build-up-a-social-project-113311ac2f96" target="_new">Hier geht es zum Blogbeitrag</a></p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Schließen</button>
          </div>
        </div>
      </div>
    </div>


    <!-- Weekend BLOG -->
    <div class="article_container">
      <img src="assets/img/presse/Weekendbeitrag_19112020.jpg" style="width:100%" data-bs-toggle="modal" data-bs-target="#weekend_presse">
      <div class="title">Weekend Magazin – 19.11.2020</div>
    </div>

    <div class="modal fade" id="weekend_presse" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-scrollable modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="modal-title" id="weekend_presseLabel">Weekend Magazin – 19.11.2020</h2>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <img src="assets/img/presse/Weekendbeitrag_19112020.jpg"  class="modal_img">
            <p>Das Magazin „Weekend“ hat am 13. November 2020 in der steiermärkischen Ausgabe über uns berichtet.</p>
            <p><a href="https://www.weekend.at/bundesland/steiermark/so-helfen-wir-dieses-jahr-dem-christkind?fbclid=IwAR1H1gB2CpCo55-MkkjHRIEGgVoRmgOBY6dXOhOz3kZ1CzD3URpvPy0Lvik" target="_new">Hier geht es zum Zeitungsartikel.</a></p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Schließen</button>
          </div>
        </div>
      </div>
    </div>

  </div>
  
  <div class="column">

    <!-- Liebocher Gemeindeblatt BLOG -->
    <div class="article_container">
      <img src="assets/img/presse/LiebochGemeindezeitung_092021.jpg" style="width:100%" data-bs-toggle="modal" data-bs-target="#lieboch1_presse">
      <div class="title">Liebocher Gemeindeblatt – 09.2021</div>
    </div>

    <div class="modal fade" id="lieboch1_presse" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-scrollable modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="modal-title" id="lieboch1_presseLabel">Liebocher Gemeindeblatt – 09.2021</h2>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <img src="assets/img/presse/LiebochGemeindezeitung_092021.jpg" class="modal_img">
            <p>Das Liebocher Gemeindeblatt hat uns in ihrer Septemberausgabe 2021 eine Doppelseite zu Verfügung gestellt. Wir freuen uns sehr, dass unserem Verein und unseren Aktionen so viel Platz gewidmet wurde. Nachzulesen gibt es unseren Beitrag auf den Seiten 14 und 15.</p>
            <p><a href="https://www.lieboch.gv.at/fileadmin/user_upload/Gemeinde_Lieboch/Gemeindezeitung/ZTG_02_2021_web.pdf?fbclid=IwAR35L1uglmcwtiwNd1ZNKR8LhHiFCWM9uAPMBfZPTN9v_nOaGZ6Nw3qx1gI" target="_new">Hier kannst du unseren Beitrag nachlesen. </a></p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Schließen</button>
          </div>
        </div>
      </div>
    </div>


    <!-- Steirerjobs BLOG -->
    <div class="article_container">
      <img src="assets/img/presse/steirerjobsBlogbeitrag_011202020.jpg" style="width:100%" data-bs-toggle="modal" data-bs-target="#steirerjobs_presse">
      <div class="title">Steirerjobs.at Blogbeitrag – 01.12.2020</div>
    </div>

    <div class="modal fade" id="steirerjobs_presse" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-scrollable modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="modal-title" id="steirerjobs_presseLabel">Steirerjobs.at Blogbeitrag – 01.12.2020</h2>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <img src="assets/img/presse/steirerjobsBlogbeitrag_011202020.jpg" class="modal_img">
            <p>Die regionale Job-Plattform steirerjobs.at hat unserem Projekt einen Blogartikel gewidmet und unsere Aktionen vorgestellt. Wir danken dem Unternehmen für die Unterstützung.</p>
            <p><a href="https://www.steirerjobs.at/news/1/204/" target="_new">Hier geht es zum Blogbeitrag.</a></p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Schließen</button>
          </div>
        </div>
      </div>
    </div>



    <!-- Herzblatt Jahresrückblick 2020 -->
    <div class="article_container">
      <img src="assets/img/presse/Herzblatt_Rückblick2020.png" style="width:100%" data-bs-toggle="modal" data-bs-target="#herzblatt_presse">
      <div class="title">Herzblatt 21 - Jahresrückblick 2020</div>
    </div>

    <div class="modal fade" id="herzblatt_presse" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-scrollable modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="modal-title" id="herzblatt_presseLabel">Herzblatt 21 - Jahresrückblick 2020</h2>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <img src="assets/img/presse/Herzblatt_Rückblick2020.png" class="modal_img">
            <p>Die Non-Profit Organisation „Herzkinder Österreich“ wird von uns seit 2020 unterstützt. Wir freuen uns sehr, dass sie unsere Unterstützung mit der Weihnachtsaktion auch in ihrem jährlichen Magazin „Herzblatt“ erwähnt haben. Im Jahresrückblick 2020 in der Ausgabe „Herzblatt 21“ findet ihr uns auf Seite 174. </p>
            <p><a href="https://www.herzkinder.at/downloads" target="_new">Hier kannst du das Magazin „Herzblatt“ downloaden</a></p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Schließen</button>
          </div>
        </div>
      </div>
    </div>


    <!-- Kleine Zeitung BLOG -->
    <div class="article_container">
      <img src="assets/img/presse/KleineZeitung_20122020.jpg" style="width:100%" data-bs-toggle="modal" data-bs-target="#kleine_zeitung_presse">
      <div class="title">Kleine Zeitung – 20.12.2020 </div>
    </div>

    <div class="modal fade" id="kleine_zeitung_presse" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-scrollable modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="modal-title" id="kleine_zeitung_presseLabel">Kleine Zeitung – 20.12.2020</h2>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <img src="assets/img/presse/KleineZeitung_20122020.jpg" class="modal_img">
            <p>Vielen Dank an die Kleine Zeitung die uns am 20.12.2020 eine Seite in der steirischen Zeitung gewidmet hat.  Für uns ist ein Wunsch in Erfüllung gegangen, den wir unbedingt mit euch teilen möchten: Einmal mit unserem Herzensprojekt in der Zeitung zu stehen!</p>
            <p><a href="https://www.kleinezeitung.at/steiermark/graz/5913596/Soziales-Engagement_Sie-erfuellen-zu-Weihnachten-207-Wuensche-ans?fbclid=IwAR1pf_x_5hrG56wH9tO0pwwldBBQcwdkeSgNAreg2VTZy_2w-wbkGMCkNPA" target="_new">Hier geht es zum Zeitungsartikel.</a></p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Schließen</button>
          </div>
        </div>
      </div>
    </div>


  </div>  
</div>
