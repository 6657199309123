import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'aktionen',
  templateUrl: './aktionen.component.html',
  styleUrls: ['./aktionen.component.scss']
})
export class AktionenComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }


}
