<p class="headline-content">Helferlein werden</p>

<p align="center">
  Willst du auch ein Helferlein sein, dann schreib uns eine Nachricht rein! Und vielleicht - wer weiß das schon? - sind leuchtende Kinderaugen dein nächster Dankeslohn!
</p>

<br>

<!--
<div class="card_information">
  <div class="card-body">
    <p class="card-text">
    Bitte beachte, dass wir für unsere diesjährige Weihnachtsaktion schon genügend Helferlein haben und daher Anmeldungen dafür nicht mehr möglich sind.
    </p>
    <p class="card-text">
      Wir würden uns aber freuen, wenn du bei unserer nächsten Aktion zu Ostern dabei bist!
    </p>
    <p class="card-text">
      Gutscheine für Lebensmittel und Drogeriemärkte nehmen wir auch gerne noch für Weihnachten an. Schreib uns dazu einfach eine Nachricht. 
    </p>

  </div>
</div> 
-->

<h2 >Kontaktformular</h2>

<div class="form_content">
  <form class="example-form" [formGroup]="contactForm" #formDirective="ngForm" (ngSubmit)="submit()">

    <div class="row">
      <div class="col-md-6">
            <!-- Anrede-->
            <mat-form-field class="example-full-width contact-gender contact-form-background">
              <mat-label class=" contact-form-background">Anrede</mat-label>
              <mat-select formControlName="gender" required>
                <mat-option></mat-option>
                <mat-option value="Frau">Frau</mat-option>
                <mat-option value="Herr">Herr</mat-option>
                <mat-option value="Frau*Herr">Frau*Herr</mat-option>
              </mat-select>

            </mat-form-field>
      </div>
      <div class="col-md-6">
                <!-- Titel vorgestellt -->
              <mat-form-field class="example-full-width contact-form-background">
                  <input matInput placeholder="Titel vorgestellt" formControlName="title">
              </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4"> <!-- vorname -->
        <mat-form-field class="example-full-width contact-form-background">
          <input matInput placeholder="Vorname" formControlName="firstname" required>
          <!-- so könnten wir einen error ausgeben
            <mat-error *ngIf="contactForm.get('firstname')?.invalid">Bitte Vornamen angeben</mat-error> -->
        </mat-form-field>
      </div>
          
      <div class="col-md-4"> <!-- nachname -->
        <mat-form-field class="example-full-width contact-form-background">
            <input matInput placeholder="Nachname" formControlName="lastname" required>
        </mat-form-field>
      </div>

        <div class="col-md-4"> <!-- titel nachgestellt -->
        <mat-form-field class="example-full-width contact-form-background">
            <input matInput placeholder="Titel nachgestellt" formControlName="title_after">
        </mat-form-field>
      </div>
    </div>


    <div class="row">
      <div class="col-md-4">
            <mat-form-field class="example-full-width contact-form-background">
            <input matInput placeholder="Straße" formControlName="street">
            </mat-form-field>
      </div>
      <div class="col-md-4">
            <mat-form-field class="example-full-width contact-form-background">
            <input matInput placeholder="PLZ" formControlName="postcode">
            </mat-form-field>
      </div>
      <div class="col-md-4">
            <mat-form-field class="example-full-width contact-form-background">
            <input matInput placeholder="Ort" formControlName="city">
            </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6"> <!-- Email -->
        <mat-form-field class="example-full-width contact-form-background" >
          <mat-label>Email</mat-label>
          <input type="email" matInput placeholder="pat@example.com" formControlName="email" required>
          <mat-error *ngIf="contactForm.get('email')?.invalid">
            <div class="error-message">Geben Sie bitte eine gültige E-Mail-Adresse an</div>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-6"> <!-- Telefonnummer -->
        <mat-form-field class="example-full-width contact-form-background">
          <mat-label>Telefonnummer</mat-label>
          <input matInput formControlName="telefon" required>
      </mat-form-field>
      </div>
    </div>

    <!-- Automatische Anmeldung alle Aktionen-->
    <label class="example-margin margin-top">Automatische Anmeldung für alle Aktionen:</label>
    <mat-radio-group [(ngModel)]="all_campains" name="campains" [ngModelOptions]="{standalone: true}">
        <mat-radio-button class="example-margin form-radio" id="all_campaigns" value="hide" name="campains">Ja</mat-radio-button>
        <mat-radio-button class="example-margin form-radio" id="one_campaign" value="show" name="campains">Nein</mat-radio-button>
    </mat-radio-group>

    <!-- only activated if not all campagins selected-->
    <section class="example-section example-full-width contact-form-background" [ngClass]="all_campains" >
      <label class="example-margin">Anmeldung für:</label>
      <mat-checkbox class="example-margin form-checkbox full-width" [ngModelOptions]="{standalone: true}" [(ngModel)]="christmas_campaign" name="christmas_campaign">Weihnachtsaktion</mat-checkbox>
      <mat-checkbox class="example-margin form-checkbox full-width" [ngModelOptions]="{standalone: true}" [(ngModel)]="easter_campaign" name="easter_campaign">Osteraktion</mat-checkbox>
      <mat-checkbox class="example-margin form-checkbox full-width" [ngModelOptions]="{standalone: true}" [(ngModel)]="schoolstart_campaign" name="schoolstart_campaign">Schulstarter</mat-checkbox>
    </section>

  <!-- praeferiertes kind-->
  <div class="example-full-width">
    <label class="example-margin">Ich tue mir leichter ein Geschenk zu finden, wenn das Kind…</label>
    <mat-radio-group [(ngModel)]="prefered_child" name="prefered_child" [ngModelOptions]="{standalone: true}" >
      <mat-radio-button class="example-margin form-radio full-width" id="prefered_equal" value="Maedchen" name="campains">ein Mädchen ist</mat-radio-button>
      <mat-radio-button class="example-margin form-radio full-width" id="prefered_girl" value="Bub" name="campains">ein Bub ist</mat-radio-button>
      <mat-radio-button class="example-margin form-radio full-width" id="prefered_boy" value="egal" name="campains">Mir egal, welches Geschlecht das Kind hat</mat-radio-button>
    </mat-radio-group>
  </div>

      <!-- Nachricht -->
    <mat-form-field class="example-full-width contact-form-background" appearance="outline">
        <mat-label>Meine Nachricht</mat-label>
        <textarea matInput placeholder="Hallo liebes wünsch mir was Team! ..." formControlName="message" rows="8"></textarea>
    </mat-form-field>


    <button mat-raised-button color="primary" type="submit">Senden</button>

  </form>
</div>

<!--just testing <button mat-stroked-button color="primary" (click)="openSnackBar()">open snack bar</button> -->

<div>
  <h2>Das sagen unsere jahrelangen Helferlein zu wünsch mir was</h2>
  <div class="row">
    <div class="col-sm-6">
      <div class="card">
        <div class="card-body">
          <p class="card-text">
            wünsch mir was bedeutet für mich, dass ich etwas von dem Glück welches mir in meinem Leben widerfährt, weitergeben kann und so einem Kind ein Lächeln ins Gesicht zaubern kann! Und was gibt es denn schöneres als glückliche Kinder mit glänzenden Augen ❤️
          </p>
          <h5 class="card-title">Kerstin Zischka</h5>
        </div>
      </div> 
    </div>
    <div class="col-sm-6">
      <div class="card">
        <div class="card-body">
          <p class="card-text">
            Etwas Gutes zu tun lohnt sich immer. Aber es ist etwas ganz Besonderes, Eltern, die es finanziell schwer haben, zu helfen ihren Kindern schöne Erinnerungen an Weihnachtsfeste, Osterfeiern und den Schulstart zu ermöglichen. Durch das Team von "wünsch mir was" wird einem das mit sehr wenig Eigenaufwand ermöglicht und man kann sicher sein, dass alles was man gibt auch dort ankommt wo es gebraucht wird.
          </p>
          <h5 class="card-title">Evelyn Maier-Pieperhoff</h5>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-6">
      <div class="card">
        <div class="card-body">
          <p class="card-text">
            Bei „wünsch mir was“ geht es genau darum, was Weihnachten immer vermitteln möchte: anderen eine Freude machen.<br><br>
            Das Schöne an „wünsch mir was“ ist, dass die Leute, die dahinter stehen sich immer mit den Kindern freuen, die sie beschenken. Hier geht es nicht um Profit, nur darum, Freude in die Welt zu tragen. Kinder aus sozial schwächeren Familien wird hier ein kleines Weihnachtswunder beschert. Da geht einem einfach das Herz auf.<br><br>
            Wenn die Welt etwas braucht, dann mehr solcher Leute, die hinter „wünsch mir was“ stehen. Zusammenrücken statt zu spalten, aufeinander Acht geben. Darum geht es.  
          </p>
          <h5 class="card-title">Dennis Pieperhoff</h5>
        </div>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="card">
        <div class="card-body">
          <p class="card-text">
            Der Verein „wünsch mir was“ ist etwas Einzigartiges. 💞 <br><br>Die Initiatorin hat es geschafft eine geniale Idee in die Tat umzusetzen, die die Herzlichkeit und die gute Tat perfekt verbindet und vereint. 🥰 <br><br>Für mich persönlich ist es nicht das Gefühl „einfach etwas Gemeinnütziges oder Ehrenamtliches tun zu müssen“, sondern die Freude, die man den Kindern schenkt. Jedes Kind hat es verdient einfach Kind zu sein – und das ist es was „wünsch mir was“ macht und auch besonders macht. ❤️
          </p>
          <h5 class="card-title">Kerstin Riedl</h5>
        </div>
      </div>
    </div>

  </div>

  <div class="row">
    <div class="col-sm-6">
      <!-- empty add here new comment -->
    </div>
    <div class="col-sm-6">
      <div class="card">
        <div class="card-body">
          <p class="card-text">
            Ich mache bei „wünsch mir was“ mit, da ich mit dieser Spende Kindern, die es nicht so gut in ihrer Kindheit haben, als es bei mir der Fall war, helfen kann. Vor allem in Zeiten wie diesen haben Spenden für wohltätige Zwecke einen hohen Stellenwert und ich freue mich hiermit meinen Beitrag zu leisten, damit es auch ärmeren Menschen etwas besser geht.  
          </p>
          <h5 class="card-title">Thomas Wammerl</h5>
        </div>
      </div>
    </div>

  </div>
</div>
