

<div class="container mt-auto py-3 bg-light">
    <div class="container">
        <div class="row">
            <div class="col-lg-11">
                <div class="row">
                    <div class="col-md-6 footer-column">
                        <h2>wünsch mir was</h2>
                        <p><a routerLink="/impressum">Impressum</a></p>
                        <p><a routerLink="/datenschutz">Datenschutz</a></p>
                        <p><a routerLink="/team">Team</a></p>
                        <p><a routerLink="/presse">Presse & Publikationen</a></p>
                    </div>
                    <div class="col-md-6 footer-column">
                        <h2><a routerLink="/helferlein-werden">Helferlein werden - Jetzt registrieren</a></h2>
                        <ul>
                            <li>
                                <div class="container">
                                    <div class="row row-cols-auto">
                                        <div class="col"><img src="assets/icons/facebook.png"></div>
                                        <div class="col">
                                            <a href="https://www.facebook.com/wuenschmirwas.at/"  target="_new" title="Facebook">
                                                <span class="inner">Facebook</span>
                                                </a>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="container">
                                    <div class="row row-cols-auto">
                                        <div class="col"><img src="assets/icons/instagram.png"></div>
                                        <div class="col">
                                            <a href="https://www.instagram.com/wuenschmirwas.at/"  target="_new" title="Instagram">
                                                <span class="inner">Instagram</span>
                                                </a>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <p>
                                    2021 © David Kerschbaumer
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>