
<p class="headline-content">Das wünsch mir was Team</p>

<img src="assets/img/team/Teamseite.jpg" class="img-fluid img-thumbnail"/>

<h1>Tatjana  Schiffer-Wieser</h1>
<div class="row">
    <div class="col-md-4">
        <img src="assets/img/team/Tatjana.jpg" class="img-fluid img-thumbnail"/>
    </div>
    <div class="col-md-8 text-padding-top">
        <p>Ich bin einer der Gründerinnen und Organisatorin dieses tollen Vereins, Sonderkindergartenpädagogin und Mama zwei kleiner Kinder.</p>
        <p>Mir war es immer wichtig, Kinderaugen zum Strahlen zu bringen - was ich jährlich mit diesem Herzensprojekt mehrfach tun kann!</p>
        <p>Auch wenn dieser Verein von uns alles abverlangt, freue ich mich, wenn das Endprodukt ein glückliches Kind ist! </p>
        <p class="quotation">&quot;Geben  und Nehmen ist die Devise - ich bin mit Geben dran!&quot;</p>
        <p>Tatjana ist für die Region Graz-Umgebung, Süd- und Weststeiermark zuständig.</p>
        <p><a href="https://www.facebook.com/tatjana.schiffer.7" target="_new">Verbinde dich mit mir auf Facebook</a><br/>
            <a href="https://www.facebook.com/wuenschmirwas.at" target="_new">wünsch mir was auf Facebook</a></p>
        <span class="fotocredit">Fotocredits: Doris Schiffer, <a href="mailto:doris@dotz.xyz">doris@dotz.xyz</a></span>
    </div>
</div>



<h1>Tamara  Schiffer, BA</h1>
<div class="row">
    <div class="col-md-4">
        <img src="assets/img/team/Tamara.jpg" class="img-fluid img-thumbnail"/>
    </div>
    <div class="col-md-8 text-padding-top">
        <p>Ich bin von Anfang an dabei und freue mich den Verein und die Aktionen wachsen zu sehen. Als Mitgründerin des Vereins und Organisatorin der Aktionen bin ich versucht in allen Lebenslagen kreativ um die Ecke zu denken. Es gibt für alles eine Lösung und ich freue mich, durch unser Handeln einen kleinen Teil am großen Ganzen beizutragen. </p>
      <p class="quotation">&bdquo;Ich habe zwar nicht viel, doch möchte ich gerne etwas abgeben. Als Helferlein des Christkinds und des Osterhasen ist es für mich der beste Weg.&ldquo;</p>
      <p>Tamara ist für die Region Graz und Südoststeiermark zuständig.</p>
      <p><a href="https://www.facebook.com/joeyleinchen" target="_new">Verbinde dich mit mir auf Facebook</a><br/>
        <a href="https://www.instagram.com/wuenschmirwas.at" target="_new">wünsch mir was auf Instagram</a></p>
      <p class="fotocredit">Fotocredits: Doris Schiffer, <a href="mailto:doris@dotz.xyz">doris@dotz.xyz</a></p>
    </div>
</div>



<h1>Martina Schmidbauer</h1>
<div class="row">
    <div class="col-md-4">
        <img src="assets/img/team/Martina.jpg" class="img-fluid img-thumbnail" />
    </div>
    <div class="col-md-8 text-padding-top">
        <p>Ich bin sehr stolz, Teil eines so tollen Vereins zu sein.</p>
        <p>Für mich ist Schenken etwas ganz besonderes, es muss nicht immer materiellen Wert haben, um jemanden glücklich zu machen. Seit der Geburt meiner Tochter weiß ich, was ein Kinderlachen bedeutet. Ich gebe gerne etwas von meiner Zeit her, um vielen Kindern so ein Lächeln zu entlocken.</p>
        <p class="quotation">&bdquo;Anderen Freude zu schenken ist selbst das Größte Geschenk.&ldquo;</p>
        <p>Martina ist für die Region Weststeiermark zuständig.</p>
        <p><a href="https://www.facebook.com/martina.kollegger"  target="_new">Verbinde dich mit mir auf Facebook</a></p>
        <span class="fotocredit">Fotocredits: Doris Schiffer, <a href="mailto:doris@dotz.xyz">doris@dotz.xyz</a></span>
    </div>
</div>
