<navbar></navbar>
<banner></banner>
<div class="seperator"></div>
<div class="container">
    <div class="row">
        <!-- content -->
        <div class="col-xl-10 content">
            <router-outlet></router-outlet>
        </div>

        <!-- sidebar -->
        <div class="col-xl-2 sidebar">
            <sidebar></sidebar>
        </div>
    </div>
    
</div>
<footer></footer>
