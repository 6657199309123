<p class="headline-content">wünsch mir was Unterstützer/innen</p>
<p>&nbsp;</p>
<p>
    Ohne unsere Unterstützer*innen hätte unser Projekt nie die Möglichkeit gehabt zu einem Verein zu werden, und vor allem nicht zu wachsen. Ein besonderer Dank gilt daher allen Unterstützer*innen und auch allen Helferleins, die sich die Zeit nahmen und in Zukunft nehmen werden, um den Wunsch eines fremden Kindes wahr werden zu lassen.
</p>
<p>
    Im Laufe der Jahre haben wir die Unterstützung von vielen Unternehmen erhalten und wir möchten hier einige davon vorstellen und danken ihnen schon jetzt für die Unterstützung in den nächsten Jahren:
</p>
<p>&nbsp;</p>

<div class="row unterstuetzer">
    <div class="col-lg-5 center">
        <a href="https://www.efs-stmk.at/" target="_new"><img src="assets/img/unterstuetzer/rene_klammer.jpg" class="img-fluid img-thumbnail"/></a>
    </div>
    <div class="col-lg-7">
        <a href="https://www.efs-stmk.at/" target="_new"><h2>EFS – Direktion René Klammer</h2></a>
        <p>Die Firma EFS – Direktion René Klammer in Graz, Andritz unterstützt den Verein jedes Jahr mit vielen Sachspenden und hat in den Anfangsjahren die Kinder des Kindergartens sowie die Betreuer*innen mit Ausflügen u.a. in den Zoo Herberstein eingeladen.</p>
    </div>
</div>


<div class="row unterstuetzer">
    <div class="col-lg-5 center">
        <a href="https://www.gw-world.com/de/" target="_new"><img src="assets/img/unterstuetzer/gebrueder_weiss.jpg" class="img-fluid img-thumbnail"/></a>
    </div>
    <div class="col-lg-7">
        <a href="https://www.gw-world.com/de/" target="_new"><h2>Gebrüder Weiss</h2></a>
        <p>Das Speditionsunternehmen Gebrüder Weiss hat jährlich die kostenlose Lieferung der Geschenke an unsere Organisationspartner „affido Österreich“ übernommen. Ohne diese Hilfe wäre die Übergabe in diesem Maß für uns nicht möglich gewesen.</p>
    </div>
</div>


<div class="row unterstuetzer ">
    <div class="col-lg-5 center">
        <a href="https://www.sysup.at/" target="_new"><img src="assets/img/unterstuetzer/sysup.jpg" class="img-fluid img-thumbnail" /></a>
    </div>
    <div class="col-lg-7">
        <a href="https://www.sysup.at/" target="_new"><h2>SysUp OG</h2></a>
        <p>Das Internet-Service-Provider Unternehmen Sysup OG hat unseren Verein mit einer kostenlosen Web- und E-Mail-Domain unterstützt. Dadurch erhielten wir eine eigene E-Mail-Adresse und einen Hostserver für diese Website.</p>
    </div>
</div>


<div class="row unterstuetzer">
    <div class="col-lg-5 center">
        <a href="https://www.diegluehbirne.at/" target="_new"><img src="assets/img/unterstuetzer/die_gluehbirne.jpg" class="img-fluid img-thumbnail"/></a>
    </div>
    <div class="col-lg-7">
        <a href="https://www.diegluehbirne.at/" target="_new"><h2>Die Glühbirne</h2></a>
        <p>Durch das Artwork, Content Creation und Designunternehmen ‚die Glühbirne‘ von Alexandra Zieger, BA wurde ein großer Traum von uns wahr. Dank ihrer Hilfe bekamen wir ein neues Logo und eine neue Corporate Identity. Alexandra, oder kurz Xandi genannt, unterstützt unseren Verein seitdem als Designerin und Content Creator aber auch als Helferlein.</p>
    </div>
</div>


<div class="row unterstuetzer">
    <div class="col-lg-5 center">
        
    </div>
    <div class="col-lg-7">
        <h2>Doris ‘dotz’ Schiffer</h2>
        <p>Die älteste Schwester aus dem Hause Schiffer unterstützt den Verein als Fotografin und ist, neben dem Unternehmen ‚die Glühbirne‘ für die Bilder und Fotos auf dieser Website verantwortlich. Doris unterstützt uns auch jährlich als Helferlein.</p>
    </div>
</div>

<div class="row unterstuetzer">
    <div class="col-lg-5 center">
        <a href="https://hoeller.at/" target="_new"><img src="assets/img/unterstuetzer/hoeller.jpg" class="img-fluid img-thumbnail"/></a>
    </div>
    <div class="col-lg-7">
        <a href="https://hoeller.at/ " target="_new"><h2>Höller Vertragsvertretung</h2></a>
        <p>Das Unternehmen Höller Vertragsvertretung aus Premstätten hat uns nach unserem Bericht in der „Kleinen Zeitung“ angeschrieben. Durch sie haben wir für unsere nächsten Aktionen viele Sachspenden erhalten. Wir freuen uns, dass das Unternehmen uns auch in den nächsten Jahren unterstützen möchte.</p>
    </div>
</div>

<div class="row unterstuetzer">
    <div class="col-lg-5 center">
        <a href="https://www.fiedlerphoto.com/" target="_new"><img src="assets/img/unterstuetzer/fiedlerphoto.jpg" class="img-fluid img-thumbnail"/></a>
    </div>
    <div class="col-lg-7">
        <a href="https://www.fiedlerphoto.com/ " target="_new"><h2>Michael Fiedler Photography</h2></a>
        <p>Der Fotograf Michael Ferlin-Fiedler unterstützt uns bei all unseren Foto-Ideen, egal wie verrückt sie sind. Er ist für unseren Verein als Hof- und Hausfotograf ehrenamtlich tätigt und hält unser Tun fotografisch fest.  Zudem ist Michael auch als Helferlein bei allen Aktionen dabei.</p>
    </div>
</div>