import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AktionenComponent } from './content/aktionen/aktionen.component';
import { DatenschutzComponent } from './content/datenschutz/datenschutz.component';
import { GeschichteComponent } from './content/geschichte/geschichte.component';
import { HelferleinComponent } from './content/helferlein/helferlein.component';
import {FormConformationComponent } from './content/helferlein/helferlein.component';
import { HomeComponent } from './content/home/home.component';
import { ImpressumComponent } from './content/impressum/impressum.component';
import { PresseComponent } from './content/presse/presse.component';
import { TeamComponent } from './content/team/team.component';
import { UnterstuetzerComponent } from './content/unterstuetzer/unterstuetzer.component';

const routes: Routes = [
  { path: '', component: HomeComponent},
  { path: 'geschichte', component: GeschichteComponent},
  { path: 'team', component: TeamComponent},
  { path: 'unterstuetzer', component: UnterstuetzerComponent},
  { path: 'helferlein-werden', component: HelferleinComponent},
  { path: 'aktionen', component: AktionenComponent},
  { path: 'impressum', component: ImpressumComponent},
  { path: 'presse', component: PresseComponent},
  { path: 'datenschutz', component: DatenschutzComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', useHash : true })],
  exports: [RouterModule]
})
export class AppRoutingModule { 

  

}
